import {ILunchRoom, ILunchRoomRequest, ILunchRoomScore, KITCHEN} from "./lunch-excel.model";

const lunchRooms: ILunchRoom[] = [
    {name: 'Markt: broodje vis', distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 4, daysOff: [1, 3, 4, 5, 6, 7], kitchen: KITCHEN.MISCELLANEOUS},
    {name: `Jumbo: Tosti's`, distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 2, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Jumbo: Broodje vlees', distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 2, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Jumbo: Brood met smeersels', distanceLevel: 2, priceLevel: 2, sizeLevel: 2, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Jumbo: Kipsaté', distanceLevel: 2, priceLevel: 2, sizeLevel: 2, healthLevel: 2, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Jumbo: Pannenkoeken', distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 1, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Sol Food', distanceLevel: 3, priceLevel: 3, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'MacDonalds', distanceLevel: 1, priceLevel: 3, sizeLevel: 3, healthLevel: 1, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Slager: vlees bestellen', distanceLevel: 1, priceLevel: 3, sizeLevel: 3, healthLevel: 3, daysOff: [1], kitchen: KITCHEN.BREAD},
    {name: 'Coöp', distanceLevel: 1, priceLevel: 2, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Little Italy', distanceLevel: 1, priceLevel: 2, sizeLevel: 4, healthLevel: 3, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Guliano', distanceLevel: 1, priceLevel: 5, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Ekmekci: Gozleme', distanceLevel: 4, priceLevel: 4, sizeLevel: 5, healthLevel: 3, daysOff: [], kitchen: KITCHEN.TURKISH},
    {name: 'Figaro', distanceLevel: 5, priceLevel: 4, sizeLevel: 3, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Little V', distanceLevel: 2, priceLevel: 4, sizeLevel: 3, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Bram Ladage', distanceLevel: 3, priceLevel: 3, sizeLevel: 5, healthLevel: 1, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Napoli', distanceLevel: 1, priceLevel: 4, sizeLevel: 2, healthLevel: 3, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Dudok', distanceLevel: 1, priceLevel: 5, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Happy Italy', distanceLevel: 3, priceLevel: 4, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Sugo', distanceLevel: 3, priceLevel: 4, sizeLevel: 2, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ITALIAN},
    {name: 'Mister salad', distanceLevel: 5, priceLevel: 4, sizeLevel: 2, healthLevel: 5, daysOff: [], kitchen: KITCHEN.SALADS},
    {name: 'Daily tasty', distanceLevel: 1, priceLevel: 3, sizeLevel: 5, healthLevel: 3, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Shanghai Papa', distanceLevel: 2, priceLevel: 5, sizeLevel: 3, healthLevel: 2, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Het Kroket Loket', distanceLevel: 4, priceLevel: 4, sizeLevel: 2, healthLevel: 1, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Boguette', distanceLevel: 5, priceLevel: 3, sizeLevel: 3, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Eazy Wok', distanceLevel: 5, priceLevel: 4, sizeLevel: 3, healthLevel: 5, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Deli Griek', distanceLevel: 3, priceLevel: 3, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.GREEK},
    {name: 'Ellniko the All Day Greek', distanceLevel: 4, priceLevel: 4, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.GREEK},
    {name: 'Poffertjessalon Seth', distanceLevel: 2, priceLevel: 3, sizeLevel: 3, healthLevel: 1, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Dutch Diner: pannenkoeken', distanceLevel: 2, priceLevel: 4, sizeLevel: 4, healthLevel: 1, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Taco Mundo', distanceLevel: 3, priceLevel: 3, sizeLevel: 4, healthLevel: 2, daysOff: [], kitchen: KITCHEN.MEXICAN},
    {name: 'De Bollenbar', distanceLevel: 3, priceLevel: 4, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Het Vlaamsch Broodhuys', distanceLevel: 4, priceLevel: 3, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Pierre', distanceLevel: 3, priceLevel: 5, sizeLevel: 4, healthLevel: 4, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Chinny', distanceLevel: 2, priceLevel: 3, sizeLevel: 2, healthLevel: 3, daysOff: [], kitchen: KITCHEN.SURINAME},
    {name: 'Akong', distanceLevel: 5, priceLevel: 3, sizeLevel: 2, healthLevel: 3, daysOff: [], kitchen: KITCHEN.SURINAME},
    {name: 'Jumbo: broodje Unox', distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 2, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Slager: broodje bestellen', distanceLevel: 1, priceLevel: 4, sizeLevel: 3, healthLevel: 3, daysOff: [1], kitchen: KITCHEN.BREAD},
    {name: 'Dutch Diner: hamburger', distanceLevel: 2, priceLevel: 4, sizeLevel: 5, healthLevel: 1, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Japanese Curry House', distanceLevel: 3, priceLevel: 4, sizeLevel: 4, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Eat Kip', distanceLevel: 3, priceLevel: 4, sizeLevel: 4, healthLevel: 2, daysOff: [], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Ekmekci: Döner', distanceLevel: 4, priceLevel: 4, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.TURKISH},
    {name: 'Cigköftem', distanceLevel: 5, priceLevel: 4, sizeLevel: 3, healthLevel: 4, daysOff: [], kitchen: KITCHEN.TURKISH},
    {name: 'Markt: kibbeling', distanceLevel: 2, priceLevel: 2, sizeLevel: 3, healthLevel: 2, daysOff: [1, 3, 4, 5, 6, 7], kitchen: KITCHEN.MISCELLANEOUS},
    {name: 'Trattoria Panini', distanceLevel: 4, priceLevel: 3, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Shabu Shabu', distanceLevel: 4, priceLevel: 4, sizeLevel: 3, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'HEMA: broodje hotdog', distanceLevel: 3, priceLevel: 3, sizeLevel: 3, healthLevel: 1, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'HEMA: broodje', distanceLevel: 3, priceLevel: 3, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Loempiakraam', distanceLevel: 3, priceLevel: 2, sizeLevel: 2, healthLevel: 4, daysOff: [], kitchen: KITCHEN.ASIAN},
    {name: 'Five Guys', distanceLevel: 2, priceLevel: 5, sizeLevel: 5, healthLevel: 5, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Toasted & Roasted: Tosti', distanceLevel: 1, priceLevel: 4, sizeLevel: 3, healthLevel: 3, daysOff: [], kitchen: KITCHEN.BREAD},
    {name: 'Toasted & Roasted: Burger', distanceLevel: 1, priceLevel: 4, sizeLevel: 4, healthLevel: 1, daysOff: [], kitchen: KITCHEN.FAST_FOOD},
    {name: 'Elliniko', distanceLevel: 3, priceLevel: 4, sizeLevel: 5, healthLevel: 2, daysOff: [], kitchen: KITCHEN.GREEK},
]

export const calculateBestLunchRoom = (request: ILunchRoomRequest) : ILunchRoomScore[] => {
    let possibleLunchRooms = lunchRooms;
    if (request.payedByBoss) {
        possibleLunchRooms = possibleLunchRooms.filter((lunchRoom) => lunchRoom.priceLevel === 5)
    }
    possibleLunchRooms = possibleLunchRooms.filter((lunchRoom) =>
        !lunchRoom.daysOff.includes(request.day)
    )

    if (possibleLunchRooms.find((lunchRoom) => request.kitchens.includes(lunchRoom.kitchen))) {
        possibleLunchRooms = possibleLunchRooms.filter((lunchRoom) => request.kitchens.includes(lunchRoom.kitchen))
    }

    const rankedLunchRooms: ILunchRoomScore[] = []
    possibleLunchRooms.forEach((lunchRoom) => {
        const healthRanking = (5 - (Math.abs(request.healthRange.score - lunchRoom.healthLevel))) * request.healthRange.weight;
        const hungerRanking = (5 - (Math.abs(request.hungerRange.score - lunchRoom.sizeLevel))) * request.hungerRange.weight;
        const moneyRanking = (5 - (Math.abs(request.moneyRange.score - lunchRoom.priceLevel))) * request.moneyRange.weight;

        const lowestWeatherTimeScore = Math.min(request.timeRange.score, request.weatherRange.score);
        const averageWeatherTimeWeight = (request.timeRange.weight + request.weatherRange.weight) / 2;
        const distanceRanking = (5 - (Math.abs(lowestWeatherTimeScore - lunchRoom.distanceLevel))) * averageWeatherTimeWeight;
        rankedLunchRooms.push({lunchRoom: lunchRoom, score: healthRanking + hungerRanking + moneyRanking + distanceRanking})
    })

    return rankedLunchRooms.sort((l1,l2) => l2.score - l1.score).slice(0, 5);
}
