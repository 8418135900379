export interface ILunchRoom {
    name: string;
    distanceLevel: number;
    priceLevel: number;
    sizeLevel: number;
    healthLevel: number;
    daysOff: number[];
    kitchen: KITCHEN;
}

export interface ILunchRoomRequest {
    [key: string]: any;
    day: number;
    kitchens: KITCHEN[];
    payedByBoss: boolean;
    healthRange: IPropertyRange;
    hungerRange: IPropertyRange;
    moneyRange: IPropertyRange;
    timeRange: IPropertyRange;
    weatherRange: IPropertyRange;
}

export interface ILunchRoomScore {
    lunchRoom: ILunchRoom;
    score: number;
}

export interface IPropertyRange {
    score: number;
    weight: number;
}

export enum KITCHEN {
    FAST_FOOD = 'Fastfood',
    SURINAME = 'Surinaams',
    ITALIAN = 'Italiaans',
    TURKISH = 'Turks',
    ASIAN = 'Aziatisch',
    GREEK = 'Grieks',
    MEXICAN = 'Mexicaans',
    BREAD = 'Brood',
    SALADS = 'Salades',
    MISCELLANEOUS = 'Divers',
}
