import {
    IonAvatar,
    IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonCol,
    IonContent, IonFooter,
    IonHeader,
    IonIcon, IonImg,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonRange,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from '@ionic/react';
import {ILunchRoomRequest, ILunchRoomScore, KITCHEN} from "./lunch-excel.model";
import React, {useState} from "react";
import {calculateBestLunchRoom} from "./lunch-excel-calculator";
import {
    bicycle,
    fastFood,
    happy,
    logoBitcoin,
    medal,
    nutrition,
    refreshOutline,
    sad,
    skull,
    sunny,
    thumbsDown,
    thumbsUp,
    thunderstorm,
    walk
} from "ionicons/icons";
import './lunch-excel.css';

const initialFormState: ILunchRoomRequest = {
    day: new Date().getDay(),
    kitchens: [],
    payedByBoss: false,
    healthRange: {score: 3, weight: 3},
    hungerRange: {score: 3, weight: 3},
    moneyRange: {score: 3, weight: 3},
    timeRange: {score: 3, weight: 3},
    weatherRange: {score: 3, weight: 3},
}

const LunchExcel: React.FC = () => {
    const [lunchRoomRequest, setLunchRoomRequest] = useState<ILunchRoomRequest>(initialFormState);
    const [lunchRoomResult, setLunchRoomResult] = useState<ILunchRoomScore[]>([]);
    const [showModal, setShowModal] = useState(false);

    const handleKitchenChange = (kitchens: KITCHEN[]) => {
        setLunchRoomRequest({ ...lunchRoomRequest, kitchens: kitchens})
    }

    const handleInputChange = (key: string, value: any) => {
        setLunchRoomRequest({ ...lunchRoomRequest, [key]: value } as ILunchRoomRequest);
    };

    const handleRangeScore = (key: string, value: number) => {
        setLunchRoomRequest({ ...lunchRoomRequest, [key]: { score: value, weight: lunchRoomRequest[key].weight }})
    };

    const handleRangeWeight = (key: string, value: number) => {
        setLunchRoomRequest({ ...lunchRoomRequest, [key]: { score: lunchRoomRequest[key].score, weight: value }})
    };

    const handleSubmit = () => {
        setLunchRoomResult(calculateBestLunchRoom(lunchRoomRequest));
    };

    const reset = () => {
        setLunchRoomRequest(initialFormState);
    }

    return (
        <IonPage>
            <IonToolbar className={'lunch-toolbar'}>
                <IonRow className={'lunch-header-row'}>
                    <IonCol size={'2'}/>
                    <IonCol size={'8'}><IonTitle className={'lunch-header'} size="large">LUNCH EXCEL</IonTitle></IonCol>
                    <IonCol size={'2'}><IonButton className={'lunch-button lunch-refresh'} size="small" onClick={(event) => reset()}><IonIcon icon={refreshOutline}/></IonButton></IonCol>
                </IonRow>
            </IonToolbar>
            <IonContent color={'light'}>
                <IonCard>
                    <IonItem>
                        <IonLabel>Keuken</IonLabel>
                        <IonSelect cancel-text={'TERUG'} value={lunchRoomRequest.kitchens} multiple={true} placeholder="Keuken(s)" onIonChange={(event: any) => handleKitchenChange(event.detail.value)}>
                            {Object.values(KITCHEN).map((kitchen, index) => (
                                <IonSelectOption value={kitchen} key={`kitchen-option-${index}`}>
                                    {kitchen}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonItem>
                    <IonItem lines={'none'}>
                        <IonLabel>De baas betaalt</IonLabel>
                        <IonToggle className={'lunch-toggle'} checked={lunchRoomRequest.payedByBoss} onIonChange={(event: any) => handleInputChange('payedByBoss', event.detail.checked)} />
                    </IonItem>
                </IonCard>

                    <IonCard>
                        <IonCardContent className='lunch-card'>
                            <IonLabel color={'dark'}>Vette hap of balansdag?</IonLabel>
                            <IonRange value={lunchRoomRequest.healthRange.score} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeScore('healthRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={fastFood} />
                                <IonIcon size="small" slot="end" icon={nutrition}/>
                            </IonRange>
                            <IonLabel color={'dark'}>Belang</IonLabel>
                            <IonRange value={lunchRoomRequest.healthRange.weight} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeWeight('healthRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thumbsDown} />
                                <IonIcon size="small" slot="end" icon={thumbsUp}/>
                            </IonRange>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent className='lunch-card'>
                            <IonLabel color={'dark'}>Dunne of dikke portemonnee?</IonLabel>
                            <IonRange value={lunchRoomRequest.moneyRange.score} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeScore('moneyRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={sad} />
                                <IonIcon size="small" slot="end" icon={logoBitcoin}/>
                            </IonRange>
                            <IonLabel color={'dark'}>Belang</IonLabel>
                            <IonRange value={lunchRoomRequest.moneyRange.weight} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeWeight('moneyRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thumbsDown} />
                                <IonIcon size="small" slot="end" icon={thumbsUp}/>
                            </IonRange>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent className='lunch-card'>
                            <IonLabel color={'dark'}>Hondenweer of lekker zonnetje?</IonLabel>
                            <IonRange value={lunchRoomRequest.weatherRange.score}  className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeScore('weatherRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thunderstorm} />
                                <IonIcon size="small" slot="end" icon={sunny}/>
                            </IonRange>
                            <IonLabel color={'dark'}>Belang</IonLabel>
                            <IonRange value={lunchRoomRequest.weatherRange.weight} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeWeight('weatherRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thumbsDown} />
                                <IonIcon size="small" slot="end" icon={thumbsUp}/>
                            </IonRange>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent className='lunch-card'>
                            <IonLabel color={'dark'}>Te veel gesnoept of uithongerd?</IonLabel>
                            <IonRange value={lunchRoomRequest.hungerRange.score} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeScore('hungerRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={happy} />
                                <IonIcon size="small" slot="end" icon={skull}/>
                            </IonRange>
                            <IonLabel color={'dark'}>Belang</IonLabel>
                            <IonRange value={lunchRoomRequest.hungerRange.weight} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeWeight('hungerRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thumbsDown} />
                                <IonIcon size="small" slot="end" icon={thumbsUp}/>
                            </IonRange>
                        </IonCardContent>
                    </IonCard>

                    <IonCard>
                        <IonCardContent className='lunch-card'>
                            <IonLabel color={'dark'}>Deadlinestress of alle tijd? </IonLabel>
                            <IonRange value={lunchRoomRequest.timeRange.score} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeScore('timeRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={bicycle} />
                                <IonIcon size="small" slot="end" icon={walk}/>
                            </IonRange>
                            <IonLabel color={'dark'}>Belang</IonLabel>
                            <IonRange value={lunchRoomRequest.timeRange.weight} className='lunch-range' min={1} max={5} step={1}  snaps={true} onIonChange={(event: any) => handleRangeWeight('timeRange', event.detail.value)}>
                                <IonIcon size="small" slot="start" icon={thumbsDown} />
                                <IonIcon size="small" slot="end" icon={thumbsUp}/>
                            </IonRange>
                        </IonCardContent>
                    </IonCard>

                <IonModal isOpen={showModal && lunchRoomResult.length > 0} cssClass='my-custom-class'>
                    <IonCard className={'lunch-result-card'}>
                        <IonImg src={`assets/gifs/${Math.floor(Math.random() * 19)}.gif`} alt={'gifje'} className={'lunch-gif'}/>
                        <IonCardHeader className={'lunch-result-header'}>
                            {lunchRoomResult[0] &&
                            <IonCardTitle className={'lunch-result-title'}>
                                #1 {lunchRoomResult[0].lunchRoom.name}
                            </IonCardTitle>}
                        </IonCardHeader>
                        <IonCardContent>
                            <IonList>
                                {lunchRoomResult.map((data, index) => (
                                    <IonItem key={index}>
                                        <IonCol size={'9'}>{data.lunchRoom.name}</IonCol>
                                        <IonCol className={'lunch-score'}>{data.score}</IonCol>
                                    </IonItem>
                                ))}</IonList>
                        </IonCardContent>
                    </IonCard>

                    <IonButton className={'lunch-button'} onClick={() => setShowModal(false)} expand="full">NOG EEN KEER</IonButton>
                </IonModal>
            </IonContent>
            <IonFooter className={'lunch-footer'}>
                <IonButton className={'lunch-button'} onClick={() => { handleSubmit(); setShowModal(true)} } expand="full">WAT WORDT HET?</IonButton>
            </IonFooter>
        </IonPage>
    );
};

export default LunchExcel;
